<template>
  <div class="h-full p-5" style="height: calc(100% - 40px)">
    <div style="float: left">
      <el-button-group style="margin-right: 20px; float: left" size="small">
        <el-button
          :type="nowType == 1 ? 'primary' : 'default'"
          size="small"
          @click="nowType = 1"
          >表单对象
        </el-button>
        <el-button
          :type="nowType == 0 ? 'primary' : 'default'"
          size="small"
          @click="nowType = 0"
          >实体对象
        </el-button>
        <el-button
          :type="nowType == 2 ? 'primary' : 'default'"
          size="small"
          @click="nowType = 2"
          >聚合对象
        </el-button>
        <el-button
          :type="nowType === null ? 'primary' : 'default'"
          size="small"
          @click="nowType = null"
          >所有
        </el-button>
      </el-button-group>

      <el-input
        style="float: right; width: 300px"
        v-model="search"
        size="small"
        placeholder="输入关键字搜索"
      />
    </div>
    <div class="text-right">
      <el-button size="small" type="warning" @click="importObject"
        >导入
      </el-button>

      <el-button size="small" type="primary" @click="addModal = true"
        >新增
      </el-button>
    </div>
    <div style="height: calc(100% - 84px)" class="scroller">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        border
        class="mt-5"
        height="100%"
        style="width: 100%"
      >
        <el-table-column label="ID" width="80" prop="id"></el-table-column>
        <el-table-column label="名称" prop="name">
          <template slot-scope="{ row }">
            <el-input
              @blur="editField(row)"
              class="edit-cell"
              v-if="showEdit[row.id]"
              v-model="row.name"
            />
            <span v-if="!showEdit[row.id]"
              ><HightlightTextVue :keyword="search" :text="row.name"
            /></span>
          </template>
        </el-table-column>
        <el-table-column label="标识" prop="code">
          <template slot-scope="{ row }">
            <HightlightTextVue :keyword="search" :text="row.code" />
          </template>
        </el-table-column>
        <el-table-column
          label="类型"
          prop="is_virtual"
          align="center"
          width="100"
        >
          <template slot-scope="{ row }">
            <el-tag
              v-if="row.enabled == '2' || row.enabled == 2"
              type="success"
              effect="dark"
              >聚合对象
            </el-tag>
            <el-tag v-else-if="row.is_virtual == 0" type="" effect="dark"
              >实体对象
            </el-tag>
            <el-tag v-else-if="row.is_virtual == 1" type="danger" effect="dark"
              >表单对象
            </el-tag>
            <el-tag v-else effect="dark" type="info">未知对象</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="说明" prop="remark">
          <template slot-scope="{ row }">
            <el-input
              @blur="editField(row)"
              class="edit-cell"
              v-if="showEdit[row.id]"
              v-model="row.remark"
            />
            <span v-if="!showEdit[row.id]"
              ><HightlightTextVue :keyword="search" :text="row.remark"
            /></span>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="160"
          prop="created_at"
        ></el-table-column>
        <el-table-column align="right" width="200" label="操作">
          <template slot-scope="scope">
            <el-dropdown
              style="margin-right: 10px"
              @command="gotoDetail($event, scope.row)"
            >
              <span class="operation-primary"
                >详情<i class="el-icon-arrow-down el-icon--right"></i
              ></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="aggregateConfig"
                  v-if="scope.row.enabled == 2"
                  >聚合配置</el-dropdown-item
                >
                <el-dropdown-item command="field">字段集</el-dropdown-item>
                <el-dropdown-item command="relation">关联关系</el-dropdown-item>
                <el-dropdown-item command="view">视图集</el-dropdown-item>
                <el-dropdown-item command="validate">验证集</el-dropdown-item>
                <!-- <el-dropdown-item disabled>更新集</el-dropdown-item> -->
                <el-dropdown-item command="behavior">行为集</el-dropdown-item>
                <el-dropdown-item command="event">事件集</el-dropdown-item>
                <el-dropdown-item command="eventGraph"
                  >事件蓝图</el-dropdown-item
                >
                <el-dropdown-item command="dict">字段字典</el-dropdown-item>
                <el-dropdown-item command="api" v-if="scope.row.enabled != 2"
                  >API集成</el-dropdown-item
                >
                <el-dropdown-item command="form">查看表单</el-dropdown-item>
                <el-dropdown-item command="migrate">迁移</el-dropdown-item>
                <el-dropdown-item command="showQoutes"
                  >查看引用</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <span
              class="operation-primary"
              v-if="!showEdit[scope.row.id]"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</span
            >
            <span
              class="operation-primary"
              v-if="showEdit[scope.row.id]"
              @click="handelCancel(scope.$index, scope.row)"
              >取消</span
            >
            <span
              class="operation-primary"
              @click="exportObject(scope.row.uuid, scope.row.name)"
              >导出</span
            >
            <span
              class="operation-primary"
              @click="onGotoField(scope.row.uuid)"
              >字段</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      style="float: right; margin-top: 8px"
      background
      ref="paginator"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page.sync="currentPage"
      :page-size="size"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <b-modal
      ref="elmodal"
      mode="drawer"
      title="新增对象"
      v-if="addModal"
      :show="addModal"
      @sure="sure"
      @cancel="addModal = false"
    >
      <el-form
        slot="content"
        ref="addForm"
        :rules="rules"
        :model="addForm"
        label-width="80px"
      >
        <el-form-item label="对象类型" prop="showType">
          <el-radio-group v-model="showType" size="mini">
            <el-radio-button label="普通对象"></el-radio-button>
            <el-radio-button label="聚合对象"></el-radio-button>
            <el-radio-button label="表单对象" disabled></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对象标识" prop="code">
          <el-input v-model="addForm.code"></el-input>
        </el-form-item>
        <el-form-item label="中文名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="对象备注">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="addForm.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="需要流程" v-if="showType == '普通对象'">
          <el-switch v-model="addForm.needFlow" />
        </el-form-item>
        <el-form-item
          label="需要用户"
          v-if="!addForm.needFlow && showType == '普通对象'"
        >
          <el-switch v-model="addForm.needUser" />
        </el-form-item>
        <el-form-item
          label="组织信息"
          v-if="!addForm.needFlow && showType == '普通对象'"
        >
          <el-switch v-model="addForm.needArchi" />
        </el-form-item>
      </el-form>
    </b-modal>
  </div>
</template>

<script>
import object from "@/api/objects";
import obOpMixin from "./objectOperationMixin.js";
import HightlightTextVue from "../../components/HightlightText.vue";
import {
  ButtonGroup,
  Tag,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  RadioButton,
} from "element-ui";

export default {
  name: "list",
  mixins: [obOpMixin],
  components: {
    HightlightTextVue,
    "el-button-group": ButtonGroup,
    "el-tag": Tag,
    "el-dropdown": Dropdown,
    "el-dropdown-menu": DropdownMenu,
    "el-dropdown-item": DropdownItem,
    "el-radio-button": RadioButton,
  },
  data() {
    return {
      tableData: [],
      search: "",
      currentPage: 1,
      tableLoading: false,
      total: 0,
      size: 14,
      timer: null,
      showEdit: {}, //显示编辑框
      addModal: false,
      prepared: false,
      showType: "普通对象",
      addForm: {
        name: "",
        code: "",
        remark: "",
        needFlow: false,
        needArchi: false,
        needUser: false,
        enabled: 1,
      },
      nowType: null,
      rules: {
        code: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "code的长度在1到30个字符之间",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9_]+$/,
            message: "code只能是字母和数字以及下划线",
          },
        ],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  watch: {
    search(val) {
      if (!this.prepared) return;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.currentPage = 1;
        clearTimeout(this.timer);
        if (this.$route.query.search !== val) {
          let query = JSON.parse(JSON.stringify(this.$route.query));
          this.$router.replace({ query: { ...query, page: 1, search: val } });
        }
      }, 300);
    },
    nowType(val) {
      if (!this.prepared) return;
      this.currentPage = 1;
      let query = { page: 1 };
      if (this.search) query.search = this.search;
      if (val !== null) query.nowType = val;
      if (val == 2) {
        query.enabled = 2;
      } else if (val == 0) {
        query.enabled = 1;
      } else {
        query.enabled = 0;
      }
      this.$router.replace({ query: query });
    },

    "$route.query": {
      handler(val, oldVal) {
        if (oldVal.nowType !== val.nowType || val.search !== oldVal.search) {
          this.currentPage = 1;
        } else {
          this.currentPage = parseInt(val.page);
        }
        this.getDatas();
      },
    },
  },
  methods: {
    sure() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.showType == "聚合对象") {
            this.addForm.enabled = 2;
          } else {
            this.addForm.enabled = 1;
          }
          object.addOne(this.addForm).then((res) => {
            if (res.data.code == 200) {
              this.addModal = false;
              this.$refs.addForm.resetFields();
              this.getDatas();
            } else {
              this.$refs.elmodal.loading = false;
            }
          });
        } else {
          this.$refs.elmodal.loading = false;
        }
      });
    },
    gotoDetail(type = "", row) {
      if (!type) {
        return;
      }
      if (type == "aggregateConfig") {
        this.$globalAction("aggregateObjectConfig", { object_uuid: row.uuid });
        return;
      } else if (type == "showQoutes") {
        this.$globalAction("showMetaQoutes", row);
        return;
      } else if (type == "migrate") {
        this.$globalAction("MetaMigrate", { type: "object", uuid: row.uuid });
        return;
      } else if (type == "form") {
        this.$router.push({
          path: "/dwh/formList",
          query: {
            obuuid: row.uuid,
            obname: row.name,
            type: "form",
            obcode: row.code,
          },
        });
      } else {
        this.$router.push({
          path: "/dwh/objects/detail",
          query: {
            obuuid: row.uuid,
            obname: row.name,
            type: type,
            is_virtual: row.is_virtual,
            enabled: row.enabled,
            obcode: row.code,
          },
        });
      }
    },
    handleEdit(index, row) {
      if (row.is_virtual == 1) {
        this.$alert("表单对象不允许编辑！", "提示");
        return;
      }
      this.$set(this.showEdit, row.id, true);
    },
    handelCancel(index, row) {
      //取消编辑
      this.$set(this.showEdit, row.id, false);
    },
    editField(data) {
      object.editOne(data.uuid, data.name, data.remark).then(() => {});
    },
    handleCurrentChange(page) {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      this.$router.push({
        path: "/dwh/objects/list",
        query: { ...query, page: page },
      });
    },
    handleSizeChange(size) {
      this.size = size;
      this.getDatas();
    },

    /**
     * @description: 跳转到新版字段管理
     * @param {*} uuid 对象uuid
     * @return {void}
     */
    onGotoField(uuid){
      this.$router.push({name:"fieldsManage",query:{object_uuid:uuid}})
    },

    getDatas() {
      this.tableLoading = true;
      object
        .getAll(
          this.currentPage,
          this.size,
          this.search,
          this.nowType,
          this.$route.query.enabled
        )
        .then((res) => {
          this.tableData = [];
          this.tableLoading = false;
          this.$nextTick(() => {
            this.tableData = res.data.data.data;
          });
          this.total = res.data.data.total;
        });
    },

    getData() {
      this.getDatas();
    },
  },

  mounted() {
    this.currentPage = parseInt(
      this.$route.query.page ? this.$route.query.page : "1"
    );
    this.nowType =
      this.$route.query.nowType === undefined
        ? null
        : this.$route.query.nowType;
    this.search = this.$route.query.search;
    this.$nextTick(() => {
      this.$refs.paginator.$data.internalCurrentPage = this.currentPage;
      this.prepared = true;
    });
    this.getDatas();
  },
};
</script>
